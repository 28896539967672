import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { TokenContext } from '../context/TokenContext';

let baseUrl = process.env.REACT_APP_ENDPOINT_URL;

const afasFetcher = async (input) => {
  const [path, queryStringParameters, token] = input;
  return axios
    .get(`${baseUrl}${path}`, {
      headers: {
        Authorization: token,
      },
      params: queryStringParameters,
    })
    .then((res) => res.data);
};

function useAfasData(path, queryStringObj = {}) {
  const token = useContext(TokenContext);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    if (token) {
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
    }
  }, [token]);
  // console.log({ shouldFetch });
  const { data, error, mutate } = useSWR(
    shouldFetch && path ? [path, queryStringObj, token] : null,
    afasFetcher,
    {
      errorRetryCount: 3,
      focusThrottleInterval: 15000,
    },
  );

  let errorMessage;
  let errorType = '';
  let statusCode = 200;

  if (error && error?.response) {
    const errorData = error.response.data;
    errorType = 'UnexpectedError';
    statusCode = errorData.statusCode;
    errorMessage = 'ERROR';
  }

  return {
    mutate,
    data,
    isLoading: !error && !data && data !== '',
    errorMessage,
    statusCode,
    errorType,
  };
}

export default useAfasData;
