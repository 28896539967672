import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GoogleAdsKeywords = ({ formField, vacancy, fieldId, setFormData }) => {
  const options = [
    {
      title: `Vacature ${vacancy?.VacancyTitle}`,
      id: 1,
    },
    {
      title: `${vacancy?.VacancyTitle} vacatues`,
      id: 2,
    },
    {
      title: `${vacancy?.VacancyTitle} werk zoeken`,
      id: 3,
    },
    {
      title: `Vacature + ${vacancy?.VacancyTitle} + ${vacancy?.Formatieplaats}`,
      id: 4,
    },
    {
      title: `Carrière ${vacancy?.VacancyTitle}`,
      id: 5,
    },
    {
      title: `Baan ${vacancy?.VacancyTitle}`,
      id: 6,
    },
    {
      title: `Jobs ${vacancy?.VacancyTitle}`,
      id: 7,
    },
    {
      title: `Functie ${vacancy?.VacancyTitle}`,
      id: 8,
    },
    {
      title: `Solliciteren ${vacancy?.VacancyTitle}`,
      id: 9,
    },
  ];

  return (
    <>
      <Autocomplete
        multiple
        id={fieldId}
        freeSolo
        options={options.map((option) => option.title)}
        value={formField.value.map((option) =>
          option?.replace('TITEL', vacancy?.VacancyTitle).replace('CITY', vacancy?.City),
        )}
        onChange={(e, newValue) => {
          setFormData({
            target: {
              name: fieldId,
              value: newValue,
            },
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.title ?? option}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
        }
        renderInput={(params) => <TextField {...params} label={formField.label} placeholder="Keyword" />}
      />
    </>
  );
};

export default GoogleAdsKeywords;
