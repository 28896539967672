import { useTranslation } from 'react-i18next';
import StepHeader from './StepHeader';
import { Box, Stack, Typography, Grid, Card, CardContent, FormControlLabel } from '@mui/material';
import { IOSSwitch } from './Switch';

const Step4 = ({ sx, channels }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box {...sx}>
        <StepHeader
          description={t('steps.description.voortgang')}
          title={t('steps.titles.step_4')}
          stepNumber={3}
        />
        <Box mt={4}>
          <Grid container spacing={4}>
            {channels.map((channel, index) => (
              <Grid item xs={6} key={index}>
                <Card
                  sx={{
                    borderRadius: '1rem',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.15)',
                    height: '100%',
                  }}
                >
                  <CardContent>
                    <Stack direction="column" justifyContent="space-between">
                      <Box alignSelf="flex-end">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              name={channel.name.toLowerCase().replace(/\s/g, '_')}
                              onChange={(e) => channel.setter(e.target.checked)}
                              value={channel.checked}
                              checked={channel.checked}
                            />
                          }
                          variant="standard"
                          sx={{ mr: 0 }}
                        />
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={4}>
                        <img
                          src={channel.image}
                          alt=""
                          width="70"
                          height="55"
                          style={{ objectFit: 'contain' }}
                        />
                        <Typography variant="body1" fontWeight={700}>
                          {channel.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Step4;
