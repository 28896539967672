import { Box, Slider, Typography } from '@mui/material';

const euro = Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

function valuetext(value) {
  return `${euro.format(value / 1000000)} per dag`;
}

const GoogleAdsBudget = ({ formField, fieldId, setFormData }) => {
  return (
    <>
      <Box mt={2}>
        <Typography gutterBottom>{formField.label}</Typography>
        <Box mt={5} px={4}>
          <Slider
            id={fieldId}
            name={fieldId}
            label={formField.label}
            defaultValue={10000000}
            onChange={setFormData}
            value={formField.value}
            step={100000}
            min={0}
            max={10000000}
            valueLabelFormat={valuetext}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
    </>
  );
};

export default GoogleAdsBudget;
