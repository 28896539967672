import { Dialog } from '@mui/material';
import GoogleAds from '../components/marketing/GoogleAds';
import { useState } from 'react';

const ConfigurationWrapper = ({ dialogOpen, onClose, children }) => {
  return (
    <Dialog
      scroll="paper"
      onClose={onClose}
      open={dialogOpen}
      hideBackdrop={true}
      sx={{ minHeight: '600px', position: 'fixed', width: "100%" }}
      className="channel-dialog"
    >
      {children}
    </Dialog>
  );
};

const ConfigureChannel = ({ channel, vacancy }) => {
  const [open, setOpen] = useState(true);

  const handleDialogClose = () => {
    setOpen(false);
  };
  switch (channel.name) {
    case 'Google Ads':
      return (
        <ConfigurationWrapper dialogOpen={open} onClose={handleDialogClose}>
          <GoogleAds channel={channel} vacancy={vacancy} onClose={handleDialogClose} />
        </ConfigurationWrapper>
      );
    case 'sms':
      return <div>SMS</div>;
    default:
      return <div>Unknown</div>;
  }
};

export default ConfigureChannel;
