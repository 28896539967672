import {
  Box,
  DialogTitle,
  IconButton,
  TextField,
  Button,
  Alert,
  DialogContent,
  DialogActions,
  Stack,
  Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import GoogleAdsKeywords from './fields/GoogleAdsKeywords';
import GoogleAdsBudget from './fields/GoogleAdsBudget';
import { useState } from 'react';

const GoogleAds = ({ channel, vacancy, onClose }) => {
  const { t } = useTranslation('common');
  const { setFormData, name, formData, setter } = channel;

  const [valid, setValid] = useState(null);

  const validate = () => {
    if (valid) return true;
    const keys = Object.keys(formData);
    if (
      keys.every((key) => {
        if (formData[key]?.required) {
          if (Array.isArray(formData[key].value)) {
            return formData[key].value.length > 0;
          }
          return formData[key].value;
        } else {
          return true;
        }
      })
    ) {
      setValid(true);
      setTimeout(function () {
        onClose();
      }, 1000);
    } else {
      setValid(false);
    }
  };
  // dialog for google ads
  return (
    <>
      <DialogTitle>
        <Box px={4} py={1}>
          <Stack
            direction="row"
            gap={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '1.5rem',
              }}
            >
              {t('confighere')}
              <br></br>
              {`${name}`}
            </span>
            <Avatar
              variant="square"
              src={channel.image}
              sx={{
                marginLeft: '1rem',
                width: '5rem',
                height: '3rem',
                '& .MuiAvatar-img': { objectFit: 'contain' },
              }}
            />
            <IconButton
              aria-label={t('close')}
              onClick={() => {
                onClose();
                setter(false);
              }}
              sx={{ marginLeft: 'auto' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box px={4} py={2}>
          {Object.keys(formData).map((key) => {
            switch (key) {
              case 'GOOGLE_ADS_KEYWORDS':
                return (
                  <>
                    <Box mb={4} key={key}>
                      <GoogleAdsKeywords
                        setFormData={setFormData}
                        formField={formData[key]}
                        fieldId={key}
                        vacancy={vacancy}
                        key={key}
                      />
                    </Box>
                  </>
                );
              case 'GOOGLE_ADS_BUDGET':
                return (
                  <>
                    <Box mb={4} key={key}>
                      <GoogleAdsBudget
                        setFormData={setFormData}
                        formField={formData[key]}
                        fieldId={key}
                        vacancy={vacancy}
                        key={key}
                      />
                    </Box>
                  </>
                );
              default:
                return (
                  <>
                    <Box mb={4} key={key}>
                      <TextField
                        {...formData[key]}
                        error={formData[key]?.required && !formData[key]?.value}
                        id={key}
                        name={key}
                        value={`${formData[key]?.value
                          ?.replace('TITEL', vacancy?.vacancyTitle)
                          .replace('CITY', vacancy?.City)}`}
                        placeholder={`${formData[key]?.placeholder
                          ?.replace('TITEL', vacancy?.vacancyTitle)
                          .replace('CITY', vacancy?.City)}`}
                        multiline
                        onChange={setFormData}
                      />
                    </Box>
                  </>
                );
            }
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={4} py={1} sx={{ width: '100%' }}>
          <Stack gap={2}>
            {valid === false && <Alert severity="error">{t('form.missingrequired')}</Alert>}
            {valid === true && <Alert severity="success">{t('form.success')}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                validate();
              }}
            >
              {t('form.complete')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </>
  );
};

export default GoogleAds;
