export const GoogleAdsConfig = {
  GOOGLE_ADS_HEADLINE1: {
    value: ``,
    placeholder: `Kom werken als TITEL`,
    type: 'text',
    label: 'Kop 1',
    required: true,
    fullWidth: true,
    inputProps: {
      maxLength: 30,
    },
    helperText: 'Max 30 tekens',
  },
  GOOGLE_ADS_HEADLINE2: {
    value: ``,
    placeholder: `Werken als TITEL`,
    type: 'text',
    label: 'Kop 2',
    fullWidth: true,
    required: true,
    inputProps: {
      maxLength: 30,
    },
    helperText: 'Max 30 tekens',
  },
  GOOGLE_ADS_HEADLINE3: {
    value: ``,
    placeholder: `Vacature TITEL`,
    type: 'text',
    label: 'Kop 3',
    fullWidth: true,
    required: true,
    inputProps: {
      maxLength: 30,
    },
    helperText: 'Max 30 tekens',
  },
  GOOGLE_ADS_DESCRIPTION1: {
    value: ``,
    placeholder: `Ben jij de TITEL die wij zoeken? Solliciteer dan nu online!`,
    type: 'text',
    rows: 2,
    label: 'Beschrijving 1',
    required: true,
    inputProps: {
      maxLength: 90,
    },
    sx: {
      width: '100%',
    },
    helperText: 'Max 90 tekens',
  },
  GOOGLE_ADS_DESCRIPTION2: {
    value: ``,
    placeholder: `Wij zijn op zoek naar een TITEL in CITY`,
    type: 'text',
    rows: 2,
    label: 'Beschrijving 2',
    required: true,
    inputProps: {
      maxLength: 90,
    },
    sx: {
      width: '100%',
    },
    helperText: 'Max 90 tekens',
  },
  GOOGLE_ADS_KEYWORDS: {
    value: [],
    type: 'keywords',
    label: 'Keywords',
  },
  GOOGLE_ADS_BUDGET: {
    value: 1000000,
    type: 'number',
    label: 'Dag budget (euro per dag)',
    required: true,
  },
};
