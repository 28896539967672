import axios from 'axios';
import { useContext } from 'react';
import useSWRMutation from 'swr/mutation';
import { TokenContext } from '../context/TokenContext';
import { Auth } from 'aws-amplify';
import { DatahubContext } from '../context/DatahubContext';

let baseUrl = process.env.REACT_APP_ENDPOINT_URL;

export const afasPoster = async (path, { arg }) => {
  const { token, body, workspace } = arg;

  const jwtToken = workspace ? (await Auth.currentSession()).getIdToken().getJwtToken() : '';
  return axios
    .post(`${baseUrl}${path}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        'X-Data-Hub-Auth-Key': jwtToken,
      },
    })
    .then((res) => res.data);
};

function useAfasDataMutation(path) {
  const token = useContext(TokenContext);
  const { workspace } = useContext(DatahubContext);
  // eslint-disable-next-line no-unused-vars
  const { trigger, isMutating, error, data } = useSWRMutation(path ? path : null, afasPoster, {
    errorRetryCount: 3,
  });

  function doTrigger(body) {
    return trigger({ token: token, body: body, workspace: workspace });
  }

  return {
    trigger: doTrigger,
    data,
    isMutating,
    error,
  };
}

export default useAfasDataMutation;
