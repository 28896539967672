import { Avatar, Box, Stack, Typography } from '@mui/material';

const StepHeader = ({ title, stepNumber, description }) => {
  return (
    <>
      <Box mt={4} sx={{ maxWidth: '780px' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            sx={(theme) => ({
              bgcolor: theme.vars.palette.primary.main,
            })}
          >
            {stepNumber}
          </Avatar>
          <Typography variant="h2">{title}</Typography>
        </Stack>
        <Box my={2}>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default StepHeader;
