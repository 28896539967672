import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Box,
  Stack,
  SvgIcon,
  Typography,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useContext } from 'react';
import { TokenContext } from '../context/TokenContext';
import { InSiteContext } from '../context/InSiteContext';
import Edit from '@mui/icons-material/Edit';
import { useState } from 'react';
import useDatahubData, { postDataHub } from '../hooks/useDatahubData';
import { DatahubContext } from '../context/DatahubContext';
import { AdsClick, CalendarMonth, Delete, Euro, Visibility } from '@mui/icons-material';
const LinkIcon = ({ props }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.3125 6.6875C11.9062 8.28125 12 10.8438 10.5938 12.5312C10.4062 12.7812 10.5312 12.6562 7.96875 15.2188C6.25 16.9375 3.46875 16.9375 1.78125 15.2188C0.0625 13.5312 0.0625 10.75 1.78125 9.03125C2.78125 8.03125 3.375 7.4375 3.75 7.0625C3.96875 6.84375 4.375 7 4.375 7.3125C4.40625 7.71875 4.4375 8.125 4.53125 8.5C4.5625 8.625 4.53125 8.75 4.4375 8.84375L3 10.2812C1.96875 11.3125 1.96875 12.9688 3 14C4.03125 15.0312 5.6875 15.0312 6.71875 14L9.0625 11.6562C10.0938 10.625 10.0938 8.96875 9.0625 7.9375C8.875 7.71875 8.625 7.5625 8.34375 7.4375C8.21875 7.34375 8.125 7.1875 8.15625 7.03125C8.1875 6.6875 8.34375 6.34375 8.625 6.09375L8.75 5.96875C8.875 5.84375 9.03125 5.8125 9.1875 5.875C9.59375 6.09375 9.96875 6.34375 10.3125 6.6875ZM15.1875 1.8125C16.9062 3.5 16.9062 6.28125 15.1875 8C14.1875 9 13.5938 9.59375 13.2188 9.96875C13 10.1875 12.5938 10.0312 12.5938 9.71875C12.5625 9.3125 12.5312 8.90625 12.4375 8.53125C12.4062 8.40625 12.4375 8.28125 12.5312 8.1875L13.9688 6.75C15 5.71875 15 4.0625 13.9688 3.03125C12.9375 2 11.2812 2 10.25 3.03125L7.90625 5.375C6.875 6.40625 6.875 8.0625 7.90625 9.09375C8.09375 9.3125 8.34375 9.46875 8.625 9.59375C8.75 9.6875 8.84375 9.84375 8.8125 10C8.78125 10.3438 8.625 10.6875 8.34375 10.9375L8.21875 11.0625C8.09375 11.1875 7.9375 11.2188 7.78125 11.1562C7.375 10.9375 7 10.6875 6.65625 10.3438C5.0625 8.75 4.96875 6.1875 6.375 4.5C6.5625 4.25 6.4375 4.375 9 1.8125C10.7188 0.09375 13.5 0.09375 15.1875 1.8125Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

const ConnectSource = ({ source, preferred }) => {
  const token = useContext(TokenContext);
  const { environmentId } = useContext(InSiteContext);
  const { workspace } = useContext(DatahubContext);
  const [edit, setEdit] = useState(false);
  const { data: campaigns, mutate: setCampaigns } = useDatahubData(
    source?.urlPath ? `/${source?.urlPath}/campaigns` : null,
    {
      dataSourceId: preferred?.dataSource?.id,
    },
    'DATA_PUTTER_API',
  );

  const { t } = useTranslation('common');
  return (
    <Grid item xs={6}>
      <Paper
        sx={{
          backgroundColor: 'white',
          boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
          padding: '.75rem 1rem',
        }}
      >
        <Stack direction={'row'} spacing={3} alignItems={'center'}>
          <img
            src={source.image}
            alt={source.name}
            width="120"
            height="50"
            style={{ objectFit: 'contain' }}
          />
          <Typography variant="body1" color="initial" fontWeight={700} style={{ flex: 1 }}>
            {source.name}
          </Typography>
          <Typography
            variant="body1"
            color="initial"
            fontWeight={400}
            style={{ flex: 2, fontSize: '0.875rem', opacity: '0.5' }}
          >
            {preferred?.dataSource ? (
              <>
                <i style={{ color: 'var(--invite-color)' }}>
                  {preferred?.dataSource?.displayName ?? preferred?.dataSource?.name}
                </i>
                {preferred?.allDataSources?.length > 1 && (
                  <>
                    <IconButton onClick={() => setEdit(true)}>
                      <Edit></Edit>
                    </IconButton>
                  </>
                )}
              </>
            ) : (
              <i>{t('management.notconnected')}</i>
            )}
            {
              <Dialog
                open={edit}
                onClose={() => setEdit(false)}
                PaperProps={{
                  style: {
                    backgroundColor: '#f1f1f1',
                  },
                }}
              >
                <DialogTitle>test</DialogTitle>
                <DialogContent
                  sx={{
                    width: '500px',
                  }}
                >
                  <FormControl
                    sx={{
                      width: '100%',
                    }}>
                    <InputLabel id="select-source">{'Select DataSource'}</InputLabel>
                    <Select
                      labelId="select-source"
                      sx={{
                        width: '100%',
                      }}
                      value={preferred?.dataSource?.id}
                      onChange={(event) => {
                        const newPreferred = preferred?.allDataSources?.find(
                          (dataSource) => dataSource.id === event.target.value,
                        );
                        postDataHub('/preferred-data-source', {
                          dataSourceId: newPreferred.id,
                          workspaceId: workspace.id,
                        });

                        preferred.setPreferred(
                          (old) => {
                            const newds = [
                              ...old.filter(
                                (dataSource) => dataSource.dataProvider !== newPreferred.dataProvider,
                              ),
                              newPreferred,
                            ];

                            return newds;
                          },
                          {
                            revalidate: false,
                          },
                        );
                      }}
                    >
                      {preferred?.allDataSources?.map((dataSource) => (
                        <MenuItem value={dataSource.id} key={dataSource.id}>
                          {dataSource?.displayName ?? dataSource?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {(campaigns ?? []).map((campaign) => {
                    return (
                      <Box
                        key={campaign?.id}
                        sx={{
                          boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
                          borderRadius: '5px',

                          margin: 1,
                          backgroundColor: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 2,
                          }}
                        >
                          <ListItemText primary={campaign?.jobTitle} secondary={campaign?.id}></ListItemText>

                          <IconButton
                            color="error"
                            onClick={() => {
                              postDataHub(
                                `/${source?.urlPath}/campaign/${campaign?.id}`,
                                {
                                  dataSourceId: preferred?.dataSource?.id,
                                },
                                'DATA_PUTTER_API',
                                'DELETE',
                              );
                              setCampaigns((old) => old.filter((c) => c.id !== campaign.id), {
                                revalidate: false,
                              });
                            }}
                          >
                            <Delete></Delete>
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderTop: '1px solid #f1f1f1',
                            width: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Visibility
                              color="primary"
                              sx={{
                                mr: 1,
                              }}
                            ></Visibility>
                            {campaign.impressions}
                          </Box>
                          <Box
                            sx={{
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',

                              borderLeft: '1px solid #f1f1f1',
                              borderRight: '1px solid #f1f1f1',
                            }}
                          >
                            <AdsClick
                              color="primary"
                              sx={{
                                mr: 1,
                              }}
                            ></AdsClick>
                            {campaign.clicks}
                          </Box>
                          <Box
                            sx={{
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              borderRight: '1px solid #f1f1f1',
                            }}
                          >
                            <Euro
                              color="primary"
                              sx={{
                                mr: 1,
                              }}
                            ></Euro>
                            {((campaign?.cost ?? 0) / 1000000).toFixed(2)}
                          </Box>
                          <Box
                            sx={{
                              padding: 2,
                              py: 'unset',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <CalendarMonth
                              color="primary"
                              sx={{
                                mr: 1,
                              }}
                            ></CalendarMonth>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                {`${campaign?.startDate?.substring(6, 8)}-${campaign?.startDate?.substring(
                                  4,
                                  6,
                                )}-${campaign?.startDate?.substring(0, 4)}`}
                              </span>
                              <span>
                                {`${campaign?.endDate?.substring(6, 8)}-${campaign?.endDate?.substring(
                                  4,
                                  6,
                                )}-${campaign?.endDate?.substring(0, 4)}`}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </DialogContent>
              </Dialog>
            }
          </Typography>
          {preferred?.dataSource ? (
            <>
              <Button
                variant="contained"
                startIcon={<CheckOutlinedIcon />}
                sx={{
                  backgroundColor: 'var(--invite-color)',
                }}
              // disabled={source.connected}
              >
                {t('management.connected')}
              </Button>
            </>
          ) : (
            <>
              <Button
                startIcon={<LinkIcon />}
                variant={'outlined'}
                onClick={async () => {
                  window
                    .open(
                      `${process.env.REACT_APP_DATA_HUB_UI_URL}/auth/sign-in?&afasToken=${token}&environment=${environmentId}`,
                      '_blank',
                    )
                    .focus();
                }}
              >
                {t('management.connect')}
              </Button>
            </>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default ConnectSource;
