import axios from 'axios';
import useSWR from 'swr';
import { Auth } from 'aws-amplify';

let urls = {
  DATA_HUB_API: process.env.REACT_APP_DATA_HUB_BASE_URL,
  DATA_PUTTER_API: process.env.REACT_APP_DATA_PUTTER_URL,
  DATA_PROVIDER_API: process.env.REACT_APP_DATA_PROVIDER_URL,
};

export async function postDataHub(path, data, type = 'DATA_HUB_API', method = 'POST') {
  if (method === 'DELETE') {
    return axios.delete(`${urls[type]}${path}`, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      params: data,
    });
  }
  return axios.post(`${urls[type]}${path}`, data, {
    headers: {
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  });
}

const dhFetcher = async (input) => {
  const [path, queryStringParameters, type] = input;
  return axios
    .get(`${urls[type]}${path}`, {
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      params: queryStringParameters,
    })
    .then((res) => res.data);
};

function useDatahubData(path, queryStringObj = {}, type = 'DATA_HUB_API') {
  // eslint-disable-next-line no-unused-vars
  const { data, error, mutate } = useSWR(path ? [path, queryStringObj, type] : null, dhFetcher);
  let errorMessage;
  let errorType = '';
  let statusCode = 200;

  if (error && error?.response) {
    const errorData = error.response.data;
    errorType = 'UnexpectedError';
    statusCode = errorData.statusCode;
    errorMessage = 'ERROR';
  }
  return {
    mutate,
    data,
    isLoading: !error && !data,
    errorMessage,
    statusCode,
    errorType,
  };
}

export default useDatahubData;
