import jsonp from 'jsonp';
import {
  Await,
  Link,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  useLoaderData,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { TokenContext } from './context/TokenContext';
import {
  Box,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Typography,
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Base from './containers/Base';
import axios from 'axios';
import New from './containers/New';
import Management from './containers/Management';
import { InSiteContext } from './context/InSiteContext';
import { DatahubContext, generateDatahubContext } from './context/DatahubContext';
import { Helmet } from 'react-helmet';

async function getGladiorToken(tokenurl, code, sessionid, publickey) {
  try {
    const {
      data: { token, environmentId },
    } = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/token`, {
      tokenurl,
      code,
      sessionid,
      publickey,
    });
    return { token, environmentId };
  } catch (error) {
    throw new TokenNotValid('Token not valid');
  }
}

async function getIntegrationSettings(dataurl) {
  try {
    return new Promise((resolve, reject) => {
      jsonp(dataurl, {}, function (err, data) {
        if (err) {
          console.error(err.message);
          reject(err);
        } else {
          resolve({
            scriptUrl: data.scriptUrl,
            cssUrl: data.cssUrl,
          });
        }
      });
    });
  } catch (e) {
    console.warn(e);
  }
}

const GladiorWrapper = ({ gladiorToken, integrationSettings, insiteUrl, environmentId, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [datahubData, setDatahubData] = useState({});
  useEffect(() => {
    if (true) {
      searchParams.set('environmentId', environmentId);
      searchParams.set('gladiorToken', gladiorToken);
      setSearchParams(searchParams);
      if (environmentId && gladiorToken && !datahubData.data && !datahubData.isLoading) {
        setDatahubData({ isLoading: true });
        generateDatahubContext(gladiorToken, environmentId).then((data) => {
          setDatahubData({ isLoading: false, data });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gladiorToken]);
  return (
    <TokenContext.Provider value={gladiorToken}>
      <InSiteContext.Provider value={{ insiteUrl, environmentId }}>
        <DatahubContext.Provider value={{ ...datahubData.data }}>
          <Helmet>
            <script src="/scripts/integration.js" type="text/javascript" />
          </Helmet>
          <link rel="stylesheet" href={integrationSettings.cssUrl} type="text/css" />
          {children}
        </DatahubContext.Provider>
      </InSiteContext.Provider>
    </TokenContext.Provider>
  );
};

function Wrapper() {
  const { gladiorToken, integrationSettings, insiteUrl, environmentId } = useLoaderData();
  return (
    <>
      <CssBaseline />
      <div className="App">
        <Suspense fallback={<Outlet />}>
          <Await
            resolve={
              new Promise(async (resolve, reject) => {
                const res = [await gladiorToken, await environmentId];
                resolve(res);
              })
            }
          >
            {([resolvedToken, resolvedEnvironmentId]) => (
              <Await resolve={insiteUrl}>
                {(resolvedInsiteUrl) => (
                  <Await resolve={integrationSettings}>
                    {(resolvedIntegrationSettings) => {
                      return (
                        <GladiorWrapper
                          gladiorToken={resolvedToken}
                          integrationSettings={resolvedIntegrationSettings}
                          insiteUrl={resolvedInsiteUrl}
                          environmentId={resolvedEnvironmentId}
                        >
                          <Outlet />
                        </GladiorWrapper>
                      );
                    }}
                  </Await>
                )}
              </Await>
            )}
          </Await>
        </Suspense>
      </div>
    </>
  );
}

class BaseError extends Error {
  constructor(message) {
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export class TokenNotValid extends BaseError { }

export class MissingIntegrationPageSearchParams extends BaseError { }

function ErrorBoundary() {
  const { t } = useTranslation('common');
  let error = useRouteError();

  if (error instanceof TokenNotValid) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined">
            <CardContent sx={{ minWidth: '300px', maxWidth: '550px' }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {t('errors.afastitle')}
              </Typography>
              <Typography variant="body1">{t('errors.afasdescription')}</Typography>
              <Link className="MuiButton" target="_parent" to={`/`}>
                {t('errors.reload')}
              </Link>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  if (error instanceof MissingIntegrationPageSearchParams) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined">
            <CardContent sx={{ minWidth: '320px', maxWidth: '550px' }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {t('errors.missingquerytitle')}
              </Typography>
              <Typography variant="body1">{t('errors.missingquerydescription')}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body1">{t('errors.error')}</Typography>
            <Link className="MuiButton MuiButtonRoot" target="_parent" to={`#`}>
              {t('errors.reload')}
            </Link>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<Wrapper />}
      errorElement={<ErrorBoundary />}
      loader={async ({ request }) => {
        const searchParams = new URL(request.url).searchParams;
        const tokenurl = searchParams.get('tokenurl');
        const code = searchParams.get('code');
        const sessionid = searchParams.get('sessionid');
        const publickey = searchParams.get('publickey');
        const dataurl = searchParams.get('dataurl');
        const gladiorToken = searchParams.get('gladiorToken');
        const environmentId = searchParams.get('environmentId');

        if (!tokenurl || !code || !sessionid || !publickey) {
          throw new MissingIntegrationPageSearchParams('Missing params');
        }
        const integrationSettings = getIntegrationSettings(dataurl);
        if (!gladiorToken) {
          const gladiorTokenRequest = getGladiorToken(tokenurl, code, sessionid, publickey);
          const gladiorToken = gladiorTokenRequest.then((data) => data.token);
          const environmentId = gladiorTokenRequest.then((data) => data.environmentId);
          return defer({
            gladiorToken: gladiorToken,
            insiteUrl: tokenurl.replace('/integrationtoken', ''),
            integrationSettings,
            environmentId: environmentId,
          });
        } else {
          return defer({
            gladiorToken: gladiorToken,
            insiteUrl: tokenurl.replace('/integrationtoken', ''),
            integrationSettings,
            environmentId: environmentId,
          });
        }
      }}
    >
      <Route path="/" element={<Base />}></Route>
      <Route path="/details/" element={<New />}></Route>
      <Route path="/new/:VacancyID" element={<New />}></Route>
      <Route path="/management/" element={<Management />}></Route>
    </Route>,
  ),
);

function App() {
  const theme = extendTheme({
    cssVarPrefix: '',
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            position: 'relative',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'unset',
            '&:hover': {
              boxShadow: 'unset',
            },
          },
        },
      },
    },
  });
  return (
    <>
      <CssVarsProvider theme={theme}>
        <RouterProvider router={router} />
      </CssVarsProvider>
    </>
  );
}

export default App;
