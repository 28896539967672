import styled from '@emotion/styled';
import { Box, LinearProgress, Stack, Typography, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TokenContext } from '../context/TokenContext';
import StepHeader from './StepHeader';
import { IOSSwitch } from './Switch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { afasPoster } from '../hooks/useAfasDataMutation';

const TextFieldLabel = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 500,
});

const Step2 = ({ config, vacancy, sx }) => {
  const token = useContext(TokenContext);
  const { t } = useTranslation('common');
  async function streamGpt(key, setter) {
    const stream = await fetch(`${process.env.REACT_APP_GPT_STREAM_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, key, vacancyId: vacancy.vacancyId }),
    });
    const streamResponse = stream.body;
    const reader = streamResponse.getReader();

    let done = false;
    let content = '';
    const decoder = new TextDecoder();

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      if (value) {
        content = content + chunkValue;
      }
      setter(content);
    }
  }

  /**
   * Post a master template to the endpoint, given a vacancyId, memoNum and body
   * @param {string} vacancyId - The id of the vacancy
   * @param {number} memoNum - The number of the memo to post
   * @param {object} body - The body of the post request
   * @returns {Promise<object>} - The response of the post request
   */
  async function postMasterTemplate(vacancyId, memoNum, body) {
    const arg = { token, body };
    let postData = await afasPoster(`/mastertemplate/${vacancyId}/${memoNum}`, { arg });
    setUpdatingBtn(0);
    return postData;
  }

  /**
   * Update a master template, given a memo number and a new content string
   * @param {number} memoNum - The number of the memo to update
   * @param {string} memoContent - The new content of the memo
   * @returns {Promise<void>} - Resolves when the master template is updated
   * @throws {Error} - If the master template update fails
   */
  async function updateMasterTemplate(memoNum, memoContent) {
    if (!vacancy.vacancyId) return;
    const vacancyId = vacancy.vacancyId;
    const body = {
      content: memoContent,
    };
    setUpdatingBtn(memoNum);
    console.log(body);
    try {
      await postMasterTemplate(vacancyId, memoNum, body);
    } catch (error) {
      throw error;
    }

  }

  async function handleChange(e, setter, key, setLoader) {
    if (e.target.checked) {
      setLoader(true);
      await streamGpt(key, setter);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  const [showUpdateBtn, setShowUpdateBtn] = useState(0);
  const [updatingBtn, setUpdatingBtn] = useState(0);

  useEffect(() => {
    console.log(showUpdateBtn);
  }, [showUpdateBtn]);

  const [introductie, setIntroductie] = useState('');
  const [introductieLoading, setIntroductieLoading] = useState(false);
  const [overvaca, setOvervaca] = useState('');
  const [overvacaLoading, setOvervacaLoading] = useState(false);
  const [werkzaamheden, setWerkzaamheden] = useState('');
  const [werkzaamhedenLoading, setWerkzaamhedenLoading] = useState(false);
  const [overons, setOverons] = useState('');
  const [overonsLoading, setOveronsLoading] = useState(false);

  useEffect(() => {
    if (!introductie && vacancy.Introductie) {
      setIntroductie(vacancy.Introductie);
    }
    if (!overvaca && vacancy.Over_de_vacature) {
      setOvervaca(vacancy.Over_de_vacature);
    }
    if (!werkzaamheden && vacancy.Jouw_werkzaamheden) {
      setWerkzaamheden(vacancy.Jouw_werkzaamheden);
    }
    if (!overons && vacancy.Over_ons) {
      setOverons(vacancy.Over_ons);
    }
  }, [vacancy, introductie, overvaca, werkzaamheden, overons]);

  const fields = [
    {
      label: config?.Heading1 ? config?.Heading1 : t('labels.introductie'),
      value: introductie,
      key: 'introductie',
      setter: setIntroductie,
      onChange: (e) => handleChange(e, setIntroductie, 'introductie', setIntroductieLoading),
      loading: introductieLoading,
    },
    {
      label: config?.Heading2 ? config?.Heading2 : t('labels.overvaca'),
      value: overvaca,
      key: 'over_de_vacature',
      setter: setOvervaca,
      onChange: (e) => handleChange(e, setOvervaca, 'overvacature', setOvervacaLoading),
      loading: overvacaLoading,
    },
    {
      label: config?.Heading3 ? config?.Heading3 : t('labels.werkzaamheden'),
      value: werkzaamheden,
      key: 'jouw_werkzaamheden',
      setter: setWerkzaamheden,
      onChange: (e) => handleChange(e, setWerkzaamheden, 'werkzaamheden', setWerkzaamhedenLoading),
      loading: werkzaamhedenLoading,
    },
    {
      label: config?.Heading4 ? config?.Heading4 : t('labels.overons'),
      value: overons,
      key: 'over_ons',
      setter: setOverons,
      onChange: (e) => handleChange(e, setOverons, 'overons', setOveronsLoading),
      loading: overonsLoading,
    },
  ];

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];

  return (
    <>
      <Box {...sx}>
        <StepHeader
          description={t('steps.description.vacaturetekst')}
          title={t('steps.titles.step_2')}
          stepNumber={2}
        />

        {fields.map((field, index) => (
          <Box mt={4} key={index}>
            {field.loading && <LinearProgress sx={{ marginBottom: '1em' }} />}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} sx={{
              width: '80%',
            }}>
              <TextFieldLabel variant="body2" my={2}>{field.label}</TextFieldLabel>
              {showUpdateBtn === index + 1 && (
                <LoadingButton
                  loading={updatingBtn === index + 1}
                  onClick={() => {
                    updateMasterTemplate(index + 1, field.value);
                  }}
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                >{t('management.updatemaster')}</LoadingButton>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2}>
              <ReactQuill
                placeholder={field.label}
                label={field.label}
                value={field.value}
                modules={quillModules}
                formats={quillFormats}
                theme="snow"
                onChange={field.setter}
                onBlur={() => setShowUpdateBtn(0)}
                onFocus={() => {
                  setTimeout(function () {
                    setShowUpdateBtn(Number(index) + 1);
                  }, 50)
                }}
              />
              <input type="hidden" name={field.key} value={field.value} />
              <Stack direction="row">
                <Tooltip title={t('labels.chatgpttooltip')}>
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
                <FormControlLabel
                  control={<IOSSwitch sx={{ ml: 2 }} onChange={field.onChange} />}
                  label={t('labels.chatgpt')}
                  labelPlacement="start"
                  variant="standard"
                  sx={{ mr: 0 }}
                />
              </Stack>
            </Stack>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Step2;
