import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import common_nl from './translations/nl/common.json';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './index.css';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang') ?? 'nl';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lang, // language to use
  resources: {
    nl: {
      common: common_nl,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);
