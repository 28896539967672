import { createContext } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';

Auth.configure({
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id: process.env.REACT_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id: process.env.REACT_APP_aws_user_pools_web_client_id,
});

async function getUserWorkspace() {
  const { data } = await axios.get(`${process.env.REACT_APP_DATA_HUB_BASE_URL}/workspaces`, {
    headers: {
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  });
  return data[0];
}

export async function generateDatahubContext(gladiorToken, environment) {
  const email = `AFAS_${environment}@gladior.ats`;
  try {
    const user = await Auth.signIn({
      username: email,
    });

    await Auth.sendCustomChallengeAnswer(user, gladiorToken);
    const ws = await getUserWorkspace();
    return { workspace: ws };
  } catch (e) {
    return { workspace: null };
  }
}

export const DatahubContext = createContext({});
